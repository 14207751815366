import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Box, Button } from '@material-ui/core'
import Logo from '../../svg/logo-grey.svg'
import Img1 from '../../svg/dev-slider-icon-1.svg'
import Img2 from '../../svg/dev-slider-icon-2.svg'
import Img3 from '../../svg/dev-slider-icon-3.svg'
import Img4 from '../../svg/dev-slider-icon-4.svg'
import ArrowIcon from '../../svg/arrowRight.svg'


SwiperCore.use([Navigation, Autoplay])


const useStyles = makeStyles((theme) => ({

  wrapper: {
    position: 'relative',
  },

  logoBox: {
    marginBottom: '80px',
  },

  outer: {
    padding: '20px',
  },

  inner: {
    // border: '0.5px solid #414141',
    background: '#fff',
    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    transition: 'transform .3s linear',

    '&:hover': {
      transform: 'translateY(-10px)',
    }
  },

  bgImg: {
    background: [theme.palette.gradientBlue.main],
    width: '100%',
    height: '129px',
    position: 'relative',
    marginBottom: '80px',
  },

  image: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
  },

  body: {
    textAlign: 'center',
  },

  title: {
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      width: '190px',
      height: '3px',
      background: [theme.palette.gradientBlue.main],
      margin: '8px auto',
    },
  },

  description: {
    marginBottom: '16px',
  },

  btnBox: {
    '& button': {
      fontWeight: 500
    },
  },

  [theme.breakpoints.up('md')]: {
    description: {
      // height: '125px',
    }
  },

  '@media (min-width: 1024px)': {
    description: {
      height: '300px',
    }
  },

  [theme.breakpoints.up('lg')]: {
    description: {
      height: '210px',
    }
  },
  
}))

const DevServicesSlider = () => {

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const classes = useStyles()

  const slides = [
    {
      title: 'Одностраничный сайт',
      desc: 'Одностраничный сайт или "посадочная страница" - это высококонверсионный сайт, целью которого, как правило, является быстрая продажа товара или услуги. Идеально подходит для запуска совместно с рекламными кампаниями.',
      link: './web-dev/landing',
      img: <Img1/>,
    },
    {
      title: 'Небольшой сайт',
      desc: 'Это сайт до 10-15 страниц. Подходит для презентации небольшого количества услуг или товаров. Достаточно высокая скорость разработки. Небольшие затраты, в отличие от высоконагруженного ресурса.',
      link: './web-dev/small-site',
      img: <Img3/>,
    },
    {
      title: 'Корпоративный сайт',
      desc: 'Корпоративный сайт давно является неотъемлемой частью любой компании. Он описывает все необходимые сведения, которые клиент должен знать о вашей деятельности. Необходим как для крупной, так и для малой компании.',
      link: './web-dev/corp-site',
      img: <Img2/>,
    },
    {
      title: 'Сайт-квиз',
      desc: 'Квиз-сайты - это страница с опросом клиента, которая подводит его к покупке товара или услуги, а также может собирть контактные данные для получения точной стоимости продукта.',
      link: './web-dev/quiz-site',
      img: <Img4/>,
    },
    {
      title: 'Интернет-магазин',
      desc: 'Это - форма электронной торговли, которая позволяет потребителям напрямую покупать товары или услуги у продавца через Интернет, используя веб-браузер или мобильное приложение.',
      link: './web-dev/internet-shop',
      img: <Img3/>,
    },
  ]

  // useEffect(() => {
  //   console.log('prev ', prevRef.current)
  //   console.log('next ', nextRef.current)
  // })


  return (
    <Box className={classes.wrapper}>
      <Swiper
        id="services-swiper"
        className={classes.swiper}
        spaceBetween={0}
        slidesPerView={1}
        speed={1000}
        autoplay={{
          delay: 2000,
        }}
        navigation={{
          prevEl: '.services-sw-btn-prev.dev-services',
          nextEl: '.services-sw-btn-next.dev-services',
        }}
        breakpoints={{
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1440: {
            slidesPerView: 4,
          },
        }}
      >
        {slides.map((slide, idx) => (
          <SwiperSlide className={classes.slide} key={`${idx}-servicesSlide`}>
            <Box className={classes.outer}>
              <Box className={classes.inner} pt={1} pb={2}>
                <Box
                  className={classes.logoBox}
                  display="flex"
                  justifyContent="center"
                >
                  <Logo />
                </Box>
                <Box className={classes.bgImg}>
                  <Box className={classes.image}>
                    {slide.img}
                  </Box>
                </Box>
                <Box className={classes.body} px={2}>
                  <h2 className={classes.title}>{slide.title}</h2>
                  <p className={classes.description}>{slide.desc}</p>
                  <Box className={classes.btnBox}>
                    <Button href={slide.link} color="primary" variant="contained" endIcon={<ArrowIcon />}>Подробнее</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <div ref={prevRef} className="swiper-button-prev services-sw-btn-prev dev-services" />
      <div ref={nextRef} className="swiper-button-next services-sw-btn-next dev-services" />
    </Box>
  )
}

export default DevServicesSlider
