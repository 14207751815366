import React from 'react'
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import MainScreen from '../../../components/page-parts/main-screen';
import ServicesDevPage from '../../../components/web-dev-page/services';
import AboutSection from '../../../components/page-parts/about-section';
import Technologies from '../../../components/page-parts/technologies';
import FormSection from '../../../components/page-parts/form-section';

import Illustration from '../../../svg/dev-page-main-svg.svg'

const WebDevelopmentPage = () => {


  const content = {
    ttlBlue: 'индивидуальная разработка продающего ',
    title: <span>веб-сайта</span>,
    subtitle: 'Ленинги, интернет-магазины, квизы и корпоративные сайты',
    buttonText: 'получить консультацию',
    buttonLink: null,
    withModal: true,
  }

  const pageTitle = 'Разработка сайтов любой сложности'
  const pageDescription = 'Одностраничные сайты, корпоративные сайты, сайты-квизы, интернет магазины. Любая сложность. Современные технологии. Индивидуальный дизайн. Точные сроки. Закажите разработку своего сайта в веб-студии Ascent.'

  // const keywords = ''

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <MainScreen content={content} svg={<Illustration />} />
      <ServicesDevPage />
      <AboutSection />
      <Technologies mt={7}/>
      <FormSection />
    </Layout>
  )
}

export default WebDevelopmentPage
